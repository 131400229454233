<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="progress-photos"
          show-select
          sort-by="inserted_at"
          :headers="headers"
          :provider="provider"
          :filter-fields="filterFields"
        >
          <template #item.actions="{ item }">
            <v-icon
              small
              color="primary"
              @click="progressPhotoStore.openEditDialog(item)"
            >
              fas fa-pencil-alt
            </v-icon>
          </template>
          <template #actions-right>
            <v-btn
              dark
              color="error"
              small
              @click="progressPhotoStore.deleteAll"
            >
              Delete
            </v-btn>
          </template>
          <template #item.insertedAt="{ item }">
            {{ formatDate(item.insertedAt) }}
          </template>
          <template #item.recipients="{ item }">
            <EReadMore :content="getRecipients(item)" />
          </template>
          <template #item.provider="{ item }">
            <span class="text-capitalize">{{ item.provider }}</span>
          </template>
          <template #item.notifyDays="{ item }">
            <span style="font-family: monospace">{{
              getCharNotifyDays(item.notifyDays)
            }}</span>
          </template>
          <template #item.isPaused="{ item }">
            <span
              class="lighten-4 text--darken-2 px-2 rounded-pill"
              :class="
                item.isPaused ? 'orange orange--text' : 'green green--text'
              "
            >
              {{ item.isPaused ? "Paused" : "Active" }}
            </span>
          </template>
          <template #item.length="{ item }">
            {{ item.cameras.length }}
          </template>
          <template #item.cameras="{ item }">
            <CamerasColumn
              :item="item"
              @open-camera-dialog="
                (camera) => cameraDialogStore.openDialog({ camera })
              "
            />
          </template>
        </ReportTable>
      </ECol>
    </ERow>
    <EditProgressPhotosDialog />
  </v-container>
</template>

<script lang="ts">
import ProgressPhotosHeader from "@/components/progressPhotos/progressPhotosHeaders"
import { getCharNotifyDays } from "@evercam/shared/utils"
import CamerasColumn from "@/components/CamerasColumn"
import ReportTable from "@/components/ReportTable"
import Vue from "vue"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import EditProgressPhotosDialog from "@/components/progressPhotos/EditProgressPhotosDialog.vue"
import { useProgressPhotoStore } from "@/stores/progress-photos"
export default Vue.extend({
  components: {
    ReportTable,
    CamerasColumn,
    EditProgressPhotosDialog,
  },
  data: () => {
    return {
      headers: ProgressPhotosHeader,
      filterFields: {
        fullname: {
          component: "TextFieldSearchFilter",
        },
        cameras: {
          component: "TextFieldSearchFilter",
        },
        recipients: {
          component: "TextFieldSearchFilter",
        },
        notifyTime: {
          component: "TextFieldSearchFilter",
        },
        timezone: {
          component: "TextFieldSearchFilter",
        },
      },
    }
  },
  head() {
    return {
      title: "Admin - Progress Photos",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useProgressPhotoStore),
    provider() {
      return AdminApi.progressPhotos.getProgressPhotos
    },
  },
  methods: {
    getCharNotifyDays,

    getRecipients(item) {
      const recipients =
        item?.provider === "email" ? item?.config?.recipients : ""

      return (recipients || "")?.split(",")
    },
  },
})
</script>
